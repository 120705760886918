import { LANGUAGE_CHANGE_REQUEST } from "../actionTypes"

const initState = {
  language: "sv",
}

export default function sampleProvide(state = initState, action) {
  switch (action.type) {
    case LANGUAGE_CHANGE_REQUEST:
      return {
        ...state,
        language: state.language === "en" ? "sv" : "en",
      }
    default:
      return state
  }
}
